(function () {
  'use strict';

  /* @ngdoc object
   * @name statistics.licenses
   * @description
   *
   */
  angular
    .module('neo.home.statistics.licenses', [
      'ui.router'
    ]);
}());
